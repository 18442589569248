import React, { useState } from 'react';
import Papa from 'papaparse';
import { saveParsedMenuItems } from '../services/firebaseService';

const CSVUpload = () => {
  const [parsedData, setParsedData] = useState([]);
  const [csvFile, setCsvFile] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (e) => {
    setCsvFile(e.target.files[0]);
    setError(null);
  };

  const handleFileUpload = () => {
    if (csvFile) {
      Papa.parse(csvFile, {
        header: true,
        complete: (result) => {
          console.log("CSV parsing complete:", result);
          setParsedData(result.data);
          setError(null);
        },
        error: (error) => {
          console.error("Error parsing CSV file:", error);
          setError(`Failed to parse CSV file: ${error.message}`);
        },
      });
    } else {
      setError("Please select a CSV file first.");
    }
  };

  const handleSaveToFirestore = async () => {
    console.log("Save to Firestore button clicked");
    setIsLoading(true);
    setError(null);
    try {
      console.log("Attempting to save parsed data:", parsedData);
      await saveParsedMenuItems(parsedData);
      console.log("Save to Firestore operation completed");
      alert("Menu items saved to Firestore successfully!");
    } catch (error) {
      console.error("Error in handleSaveToFirestore:", error);
      setError(`Failed to save menu items to Firestore: ${error.message}`);
      alert(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h3>Upload Menu Items CSV</h3>
      <input type="file" accept=".csv" onChange={handleFileChange} />
      <button onClick={handleFileUpload}>Parse CSV</button>
      {error && <div style={{color: 'red'}}>{error}</div>}
      {isLoading && <div>Loading...</div>}
      {parsedData.length > 0 && (
        <div>
          <h4>Parsed Menu Items ({parsedData.length})</h4>
          <pre>{JSON.stringify(parsedData.slice(0, 5), null, 2)}</pre>
          {parsedData.length > 5 && <p>... (showing first 5 items)</p>}
          <button onClick={handleSaveToFirestore} disabled={isLoading}>
            {isLoading ? 'Saving...' : 'Save to Firestore'}
          </button>
        </div>
      )}
    </div>
  );
};

export default CSVUpload;