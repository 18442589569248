// MenuModal.js
import React from 'react';
import './MenuModal.css';

const MenuModal = ({ imageUrl, onClose }) => {
  if (!imageUrl) return null;

  return (
    <div className="menu-modal-overlay" onClick={onClose}>
      <div className="menu-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>X</button>
        <img src={imageUrl} alt="Menu" className="menu-modal-image" />
      </div>
    </div>
  );
};

export default MenuModal;
