import React, { useState, useEffect, useRef } from 'react';
import './CityDropdown.css';

const CityDropdown = ({ venueItems, onCitySelect }) => {
  const [cities, setCities] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState('');
  const dropdownRef = useRef(null);

  useEffect(() => {
    const extractCity = (address) => {
      const parts = address.split(',');
      return parts.length > 1 ? parts[1].trim() : '';
    };

    const uniqueCities = [...new Set(venueItems.map(venue => extractCity(venue.address)))];
    setCities(['All', ...uniqueCities.sort()]); // Add 'All' at the beginning of the array
  }, [venueItems]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCitySelect = (city) => {
    setSelectedCity(city === 'All' ? '' : city);
    onCitySelect(city === 'All' ? '' : city);
    setIsOpen(false);
  };

  return (
    <div className="city-dropdown" ref={dropdownRef}>
      <button className="city-dropdown-toggle" onClick={toggleDropdown}>
        <i className="fas fa-map-marker-alt"></i>
        <span className="selected-city">{selectedCity || 'All Cities'}</span>
      </button>
      {isOpen && (
        <div className="city-dropdown-menu">
          {cities.map((city, index) => (
            <div 
              key={index} 
              className={`city-dropdown-item ${city === 'All' ? 'all-cities' : ''}`} 
              onClick={() => handleCitySelect(city)}
            >
              {city}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CityDropdown;