// src/AuthContext.js
import React, { createContext, useState, useEffect } from "react";
import { monitorAuthState, fetchVenueItems } from "./services/firebaseService";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [venueItems, setVenueItems] = useState([]);

  useEffect(() => {
    const unsubscribe = monitorAuthState(
      async (user) => {
        setCurrentUser(user);
        if (user) {
          const venues = await fetchVenueItems(user.uid);
          setVenueItems(venues);
        }
      },
      () => {
        setCurrentUser(null);
        setVenueItems([]);
      }
    );

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, venueItems }}>
      {children}
    </AuthContext.Provider>
  );
};
