import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
    apiKey: "AIzaSyATfjXiFc5FHpHQu63uWGzSEPKcAQ9Ntus",
    authDomain: "spenu2311.firebaseapp.com",
    projectId: "spenu2311",
    storageBucket: "spenu2311.appspot.com",
    messagingSenderId: "906502934604",
    appId: "1:906502934604:web:3b67ed3d04a37b5d49a4e9",
    measurementId: "G-9W7YL29ZZD"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };
