import React, { useState, useEffect } from 'react';
import { fetchVenueItems, fetchMenuItems } from '../services/firebaseService';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import './ViewAllVenues.css';

const ViewAllVenues = ({ searchQuery, priceFilter }) => {
  const [venues, setVenues] = useState([]);
  const [menuItems, setMenuItems] = useState([]); // State for menu items
  const [filteredVenues, setFilteredVenues] = useState([]);
  const [priceFilters, setPriceFilters] = useState([]);
  const [regionFilters, setRegionFilters] = useState([]);
  const [cuisineFilters, setCuisineFilters] = useState([]);
  const [allRegions, setAllRegions] = useState([]);
  const [allCuisines, setAllCuisines] = useState([]);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyATfjXiFc5FHpHQu63uWGzSEPKcAQ9Ntus', // Replace with your actual API key
  });

  useEffect(() => {
    const loadItems = async () => {
      const venuesData = await fetchVenueItems();
      const menusData = await fetchMenuItems(); // Fetch menu items
      setVenues(venuesData);
      setMenuItems(menusData); // Set menu items state
      setFilteredVenues(venuesData);

      // Extract unique regions and cuisines
      const regions = [...new Set(venuesData.map((venue) => extractCity(venue.address)))].sort();
      setAllRegions(regions);

      const cuisines = [
        ...new Set(venuesData.flatMap((venue) => venue.cuisine.split(',').map((c) => c.trim()))),
      ].sort();
      setAllCuisines(cuisines);
    };
    loadItems();
  }, []);

  useEffect(() => {
    let filtered = venues;

    if (searchQuery) {
      const lowerCaseQuery = searchQuery.toLowerCase();

      // Filter menu items based on the search query
      const filteredMenus = menuItems.filter(
        (menu) => menu.extractedText.toLowerCase().includes(lowerCaseQuery)
      );

      // Find venues that match the search query or have matching menu items
      filtered = filtered.filter(
        (venue) =>
          venue.venuename.toLowerCase().includes(lowerCaseQuery) ||
          venue.cuisine.toLowerCase().includes(lowerCaseQuery) ||
          extractCity(venue.address).toLowerCase().includes(lowerCaseQuery) ||
          filteredMenus.some((menu) => menu.venueid.trim() === venue.venueid.trim()) // Check if any menu item matches the venue
      );
    }

    if (priceFilter && priceFilter !== 'All') {
      filtered = filtered.filter((venue) => venue.price === priceFilter);
    }

    if (priceFilters.length > 0) {
      filtered = filtered.filter((venue) => priceFilters.includes(venue.price));
    }

    if (regionFilters.length > 0) {
      filtered = filtered.filter((venue) => regionFilters.includes(extractCity(venue.address)));
    }

    if (cuisineFilters.length > 0) {
      filtered = filtered.filter((venue) =>
        venue.cuisine.split(',').some((c) => cuisineFilters.includes(c.trim()))
      );
    }

    setFilteredVenues(filtered);
  }, [searchQuery, priceFilter, priceFilters, regionFilters, cuisineFilters, venues, menuItems]); // Add menuItems to dependencies

  const handlePriceFilter = (price) => {
    setPriceFilters((prevFilters) =>
      prevFilters.includes(price) ? prevFilters.filter((p) => p !== price) : [...prevFilters, price]
    );
  };

  const handleRegionFilter = (region) => {
    setRegionFilters((prevFilters) =>
      prevFilters.includes(region) ? prevFilters.filter((r) => r !== region) : [...prevFilters, region]
    );
  };

  const handleCuisineFilter = (cuisine) => {
    setCuisineFilters((prevFilters) =>
      prevFilters.includes(cuisine) ? prevFilters.filter((c) => c !== cuisine) : [...prevFilters, cuisine]
    );
  };

  const extractCity = (address) => {
    const parts = address.split(',');
    return parts.length > 1 ? parts[1].trim() : '';
  };

  const handleCardClick = (id) => {
    window.open(`/venue/${id}`, '_blank');
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="view-all-venues">
      <h1>{filteredVenues.length} Restaurants</h1>
      <div className="venues-content">
        <div className="filters-column">
          <div className="filter-section">
            <h3><i className="fas fa-tag icon"></i> Price</h3>
            <div className="price-buttons">
              {['£', '££', '£££', '££££', '£££££'].map((price) => (
                <button
                  key={price}
                  className={`price-button ${priceFilters.includes(price) ? 'active' : ''}`}
                  onClick={() => handlePriceFilter(price)}
                >
                  {price}
                </button>
              ))}
            </div>
          </div>
          <div className="filter-section">
            <h3><i className="fas fa-map-marker-alt icon"></i> Regions</h3>
            <div className="region-checkboxes">
              {allRegions.map((region) => (
                <label key={region} className="region-checkbox">
                  <input
                    type="checkbox"
                    checked={regionFilters.includes(region)}
                    onChange={() => handleRegionFilter(region)}
                  />
                  {region}
                </label>
              ))}
            </div>
          </div>
          <div className="filter-section">
            <h3><i className="fas fa-utensils icon"></i> Cuisine</h3>
            <div className="cuisine-checkboxes">
              {allCuisines.map((cuisine) => (
                <label key={cuisine} className="cuisine-checkbox">
                  <input
                    type="checkbox"
                    checked={cuisineFilters.includes(cuisine)}
                    onChange={() => handleCuisineFilter(cuisine)}
                  />
                  {cuisine}
                </label>
              ))}
            </div>
          </div>
        </div>
        <div className="venues-all-list">
          {filteredVenues.map((venue, index) => (
            <div key={venue.id} className="venue-all-card" onClick={() => handleCardClick(venue.id)}>
              <img
                src={venue.imageDownloadUrl}
                alt={venue.venuename}
                className="venue-all-image"
                loading="lazy"
              />              
              <div className="venue-all-info">
                <h2 className="venue-all-name">{`${index + 1}. ${venue.venuename}`}</h2>
                <div className="venue-all-details">
                  <span className="venue-all-price">{venue.price}</span>
                  <span className="venue-all-cuisine">{venue.cuisine}</span>
                  <span className="venue-all-location">{extractCity(venue.address)}</span>
                </div>
                {venue.promo && <div className="venue-promo">{venue.promo}</div>}
              </div>
            </div>
          ))}
        </div>
        <div className="venues-all-map">
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '100%' }}
            center={{ lat: 51.5074, lng: -0.1278 }}
            zoom={10}
          >
            {filteredVenues.map((venue, index) => (
              <Marker
                key={venue.id}
                position={{ lat: venue.latitude, lng: venue.longitude }}
                label={`${index + 1}`}
              />
            ))}
          </GoogleMap>
        </div>
      </div>
    </div>
  );
};

export default ViewAllVenues;
