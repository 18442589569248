// src/pages/AdminPanel.js
import React, { useContext, useEffect } from 'react';
import { VenueContext } from '../contexts/VenueContext';
import AddVenue from '../components/AddVenue';
import './AdminPanel.css'; // Create a CSS file for styling

const AdminPanel = () => {
  const { venues, users, addVenue } = useContext(VenueContext);

  useEffect(() => {
    if (!venues) {
      console.error("No venues found or failed to fetch venues.");
    }
  }, [venues]);

  return (
    <div className="admin-panel">
      <h1>Admin Panel</h1>
      <AddVenue />
      <div className="venue-list">
        <h2>Venues</h2>
        {(!venues || venues.length === 0) ? (
          <p>No venues available.</p>
        ) : (
          <ul>
            {venues.map(venue => (
              <li key={venue.id}>
                <h3>{venue.venuename}</h3>
                <p>{venue.description}</p>
                <p><strong>Owner:</strong> {venue.email}</p>
                {/* Add more venue details as needed */}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default AdminPanel;
