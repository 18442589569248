import React from 'react';
import './CustomAlert.css';
//import '../Styles.css';


const CustomAlert = ({ message, onClose }) => {
  return (
    <div className="custom-alert">
      <div className="custom-alert-content">
        <span className="custom-alert-close" onClick={onClose}>&times;</span>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default CustomAlert;
