// src/App.js
import React, { useState, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, AuthContext } from './AuthContext';
import { VenueProvider } from './contexts/VenueContext';
import Login from './pages/Login';
import VenueList from './pages/VenueList';
import PublicView from './pages/PublicView';
import VenueDetail from './pages/VenueDetail';
import AdminPanel from './pages/AdminPanel';
import Header from './components/Header';
import Footer from './components/Footer';
import ViewAllVenues from './pages/ViewAllVenues';
import PrivacyPolicy from './pages/PrivacyPolicy'; // Import the Privacy Policy component
import './App.css';

function App() {
  const [searchQuery, setSearchQuery] = useState('');
  const [priceFilter, setPriceFilter] = useState('All');

  return (
    <AuthProvider>
      <VenueProvider>
        <Router>
          <AppContent searchQuery={searchQuery} setSearchQuery={setSearchQuery} priceFilter={priceFilter} setPriceFilter={setPriceFilter} />
        </Router>
      </VenueProvider>
    </AuthProvider>
  );
}

function AppContent({ searchQuery, setSearchQuery, priceFilter, setPriceFilter }) {
  const location = useLocation(); // Use location to determine the current route
  const hideHeaderFooter = location.pathname === '/privacy-policy';

  return (
    <div className="App">
      {!hideHeaderFooter && (
        <ConditionalHeader
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          priceFilter={priceFilter}
          setPriceFilter={setPriceFilter}
        />
      )}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/venues" element={<ProtectedRoute component={VenueList} />} />
        <Route path="/admin" element={<AdminProtectedRoute component={AdminPanel} />} />
        <Route path="/public" element={<PublicView searchQuery={searchQuery} priceFilter={priceFilter} />} />
        <Route path="/venue/:id" element={<VenueDetail />} />
        <Route path="/" element={<PublicView searchQuery={searchQuery} priceFilter={priceFilter} />} />
        <Route path="/all-venues" element={
            <ViewAllVenues
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              priceFilter={priceFilter}
              setPriceFilter={setPriceFilter}
            />
          }
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* Privacy Policy Route */}
      </Routes>
      {!hideHeaderFooter && <Footer />}
    </div>
  );
}

function ConditionalHeader({ searchQuery, setSearchQuery, priceFilter, setPriceFilter }) {
  const location = useLocation();
  const isVenueDetailPage = location.pathname.startsWith('/venue/');
  const isLoginPage = location.pathname === '/login';
  const isVenuesPage = location.pathname === '/venues';

  if (isVenueDetailPage || isLoginPage || isVenuesPage) {
    return null; // Don't render the header on these specific pages
  }

  return (
    <Header
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      priceFilter={priceFilter}
      setPriceFilter={setPriceFilter}
    />
  );
}

function ProtectedRoute({ component: Component }) {
  const { currentUser } = useContext(AuthContext);
  return currentUser ? <Component /> : <Navigate replace to="/login" />;
}

function AdminProtectedRoute({ component: Component }) {
  const { currentUser } = useContext(AuthContext);
  if (!currentUser) {
    return <Navigate replace to="/login" />;
  }
  if (currentUser.email !== 'admin@spenu.com') {
    return <Navigate replace to="/venues" />;
  }
  return <Component />;
}

export default App;
