import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchVenueItems, fetchMenuItems } from '../services/firebaseService';
import MenuModal from '../components/MenuModal';
import './PublicView.css';

const PublicView = ({ searchQuery, priceFilter }) => {
  const [venueItems, setVenueItems] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [filteredSpecialMenuItems, setFilteredSpecialMenuItems] = useState([]);
  const [filteredEventItems, setFilteredEventItems] = useState([]);
  const [allVenues, setAllVenues] = useState([]);
  const [newToSpenu, setNewToSpenu] = useState([]);
  const [showSpecialMenuItemsScrollButtons, setShowSpecialMenuItemsScrollButtons] = useState(false);
  const [showEventItemsScrollButtons, setShowEventItemsScrollButtons] = useState(false);
  const [showAllVenuesScrollButtons, setShowAllVenuesScrollButtons] = useState(false);
  const [showNewToSpenuScrollButtons, setShowNewToSpenuScrollButtons] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState('');

  const navigate = useNavigate();

  const specialMenuItemsRowRef = useRef(null);
  const eventItemsRowRef = useRef(null);
  const allVenuesRowRef = useRef(null);
  const newToSpenuRowRef = useRef(null);

  useEffect(() => {
    const loadItems = async () => {
      const venues = await fetchVenueItems();
      const menus = await fetchMenuItems();
      setVenueItems(venues);
      setMenuItems(menus);

      categorizeAndFilter(venues, menus, searchQuery, priceFilter);
    };
    loadItems();
  }, []);

  useEffect(() => {
    categorizeAndFilter(venueItems, menuItems, searchQuery, priceFilter);
  }, [searchQuery, priceFilter, venueItems, menuItems]);

  useEffect(() => {
    const updateScrollButtons = () => {
      checkScrollButtons(specialMenuItemsRowRef, setShowSpecialMenuItemsScrollButtons);
      checkScrollButtons(eventItemsRowRef, setShowEventItemsScrollButtons);
      checkScrollButtons(allVenuesRowRef, setShowAllVenuesScrollButtons);
      checkScrollButtons(newToSpenuRowRef, setShowNewToSpenuScrollButtons);
    };

    updateScrollButtons();
    window.addEventListener('resize', updateScrollButtons);

    return () => {
      window.removeEventListener('resize', updateScrollButtons);
    };
  }, [filteredSpecialMenuItems, filteredEventItems, allVenues, newToSpenu]);

  const checkScrollButtons = (ref, setShowButtons) => {
    if (ref.current) {
      setShowButtons(ref.current.scrollWidth > ref.current.clientWidth);
    }
  };

  const categorizeAndFilter = (venues, menus, query, price) => {
    const searchTerms = query.toLowerCase().split(' ').filter(term => term.length > 0);

    // Filter venues
    const filteredVenues = venues.filter(venue =>
      (searchTerms.some(term => 
        venue.venuename.toLowerCase().includes(term) ||
        venue.cuisine.toLowerCase().includes(term) ||
        extractCity(venue.address).toLowerCase().includes(term)
      )) &&
      (price === 'All' || venue.price === price)
    );

    // Filter menus
    const filteredMenus = menus.filter(menu =>
      searchTerms.every(term => menu.extractedText.toLowerCase().includes(term)) ||
      filteredVenues.some(venue => venue.venueid.trim() === menu.venueid.trim())
    );

    // Get venues that have matching menu items
    const venuesWithMatchingMenus = venues.filter(venue =>
      filteredMenus.some(menu => menu.venueid.trim() === venue.venueid.trim())
    );

    // Combine filtered venues with venues that have matching menu items
    const allMatchingVenues = [...new Set([...filteredVenues, ...venuesWithMatchingMenus])];

    // Filter for New To Spenu (active === false) and All Venues (active === true)
    const newToSpenuVenues = allMatchingVenues.filter(venue => venue.active === false);
    const otherVenues = allMatchingVenues.filter(venue => venue.active === true);

    // Filter special menu items
    const specialMenuItems = filteredMenus.filter(menu => menu.menuType === 'Specials');

    // Filter event items
    const eventItems = filteredMenus.filter(menu => menu.menuType === 'Events');

    setFilteredSpecialMenuItems(specialMenuItems);
    setFilteredEventItems(eventItems);
    setNewToSpenu(newToSpenuVenues);
    setAllVenues(otherVenues);
  };

  const extractCity = (address) => {
    const parts = address.split(',');
    return parts.length > 1 ? parts[1].trim() : '';
  };

  const handleCardClick = (id) => {
    window.open(`/venue/${id}`, '_blank');
  };

  const handleMenuItemClick = (imageUrl) => {
    setModalImageUrl(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImageUrl('');
  };

  const scrollLeft = (ref) => {
    ref.current.scrollBy({ left: -300, behavior: 'smooth' });
  };

  const scrollRight = (ref) => {
    ref.current.scrollBy({ left: 300, behavior: 'smooth' });
  };

  const renderVenueCard = (venue) => (
    <div key={venue.id} className="venue-card" onClick={() => handleCardClick(venue.id)}>
      <img src={venue.imageDownloadUrl} alt={venue.venuename} className="venue-image" loading="lazy"/>
      <div className="venue-info">
        <h3 className="venue-name">{venue.venuename}</h3>
        <p className="venue-cuisine-price">
          <span className="venue-cuisine">{venue.cuisine}</span>
          <span className="venue-price">{venue.price}</span>
        </p>
        <p className="venue-address">{venue.address.split(',')[1].trim()}</p>
        {venue.promo && <p className="venue-promo">{venue.promo}</p>}
      </div>
    </div>
  );

  return (
    <div className="public-view">
      {filteredSpecialMenuItems.length > 0 && (
        <section className="venue-section">
          <div className="section-header">
            <h2>Special Menus</h2>
          </div>
          <div className="venue-row-container">
            <div className="scroll-buttons-container">
              {showSpecialMenuItemsScrollButtons && <button className="scroll-button left" onClick={() => scrollLeft(specialMenuItemsRowRef)}>{"<"}</button>}
              <div className="venue-row" ref={specialMenuItemsRowRef}>
                {filteredSpecialMenuItems.map(item => (
                  <div key={item.id} className="menu-item-card" onClick={() => handleMenuItemClick(item.imageDownloadUrl)}>
                    <img 
                      src={item.imageDownloadUrl} 
                      alt={item.title} 
                      className="menu-item-image"
                      onError={(e) => {
                        console.error(`Error loading image for ${item.title}:`, e);
                        e.target.src = '/path/to/fallback/image.jpg';
                      }}
                    />
                    <div className="menu-item-info">
                      <h3 className="menu-item-name">{item.title || 'No title'}</h3>
                    </div>
                  </div>
                ))}
              </div>
              {showSpecialMenuItemsScrollButtons && <button className="scroll-button right" onClick={() => scrollRight(specialMenuItemsRowRef)}>{">"}</button>}
            </div>
          </div>
        </section>
      )}

      {newToSpenu.length > 0 && (
        <section className="venue-section">
          <div className="section-header">
            <h2>Featured Venues</h2>
          </div>
          <div className="venue-row-container">
            <div className="scroll-buttons-container">
              {showNewToSpenuScrollButtons && <button className="scroll-button left" onClick={() => scrollLeft(newToSpenuRowRef)}>{"<"}</button>}
              <div className="venue-row" ref={newToSpenuRowRef}>
                {newToSpenu.map(renderVenueCard)}
              </div>
              {showNewToSpenuScrollButtons && <button className="scroll-button right" onClick={() => scrollRight(newToSpenuRowRef)}>{">"}</button>}
            </div>
          </div>
        </section>
      )}

      {allVenues.length > 0 && (
        <section className="venue-section">
          <div className="section-header">
            <h2>All Venues</h2>
          </div>
          <div className="venue-row-container">
            <div className="scroll-buttons-container">
              {showAllVenuesScrollButtons && <button className="scroll-button left" onClick={() => scrollLeft(allVenuesRowRef)}>{"<"}</button>}
              <div className="venue-row" ref={allVenuesRowRef}>
                {allVenues.map(renderVenueCard)}
              </div>
              {showAllVenuesScrollButtons && <button className="scroll-button right" onClick={() => scrollRight(allVenuesRowRef)}>{">"}</button>}
            </div>
          </div>
        </section>
      )}

      {filteredEventItems.length > 0 && (
        <section className="venue-section">
          <div className="section-header">
            <h2>Events</h2>
          </div>
          <div className="venue-row-container">
            <div className="scroll-buttons-container">
              {showEventItemsScrollButtons && <button className="scroll-button left" onClick={() => scrollLeft(eventItemsRowRef)}>{"<"}</button>}
              <div className="venue-row" ref={eventItemsRowRef}>
                {filteredEventItems.map(item => (
                  <div key={item.id} className="menu-item-card" onClick={() => handleMenuItemClick(item.imageDownloadUrl)}>
                    <img 
                      src={item.imageDownloadUrl} 
                      alt={item.title} 
                      className="menu-item-image"
                      onError={(e) => {
                        console.error(`Error loading image for ${item.title}:`, e);
                        e.target.src = '/path/to/fallback/image.jpg';
                      }}
                    />
                    <div className="menu-item-info">
                      <h3 className="menu-item-name">{item.title || 'No title'}</h3>
                    </div>
                  </div>
                ))}
              </div>
              {showEventItemsScrollButtons && <button className="scroll-button right" onClick={() => scrollRight(eventItemsRowRef)}>{">"}</button>}
            </div>
          </div>
        </section>
      )}

      {isModalOpen && (
        <MenuModal imageUrl={modalImageUrl} onClose={closeModal} />
      )}
    </div>
  );
};

export default PublicView;