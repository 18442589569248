// src/contexts/VenueContext.js
import React, { createContext, useState, useEffect } from 'react';
import { fetchUsers, fetchVenueItems, saveVenue } from '../services/firebaseService';

export const VenueContext = createContext();

export const VenueProvider = ({ children }) => {
  const [venues, setVenues] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const loadUsersAndVenues = async () => {
      try {
        const fetchedUsers = await fetchUsers();
        const fetchedVenues = await fetchVenueItems();
        setUsers(fetchedUsers);
        setVenues(fetchedVenues);
      } catch (error) {
        console.error("Error loading users and venues:", error);
      }
    };

    loadUsersAndVenues();
  }, []);

  const addVenue = async (venueData) => {
    try {
      await saveVenue(venueData);
      setVenues([...venues, venueData]);
    } catch (error) {
      console.error("Error adding venue:", error);
    }
  };

  return (
    <VenueContext.Provider value={{ venues, users, addVenue }}>
      {children}
    </VenueContext.Provider>
  );
};