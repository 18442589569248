// src/services/firebaseService.js
import { auth, db } from '../firebase-config';
import { onAuthStateChanged, createUserWithEmailAndPassword } from 'firebase/auth';
import { collection, getDocs, writeBatch, serverTimestamp, updateDoc, deleteDoc, doc, addDoc, setDoc, query, where } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage";
import Papa from 'papaparse'; // Add this import statement


// Function to monitor auth state changes
export const monitorAuthState = (onUserAuthenticated, onUserNotAuthenticated) => {
  return onAuthStateChanged(auth, user => {
    if (user) {
      onUserAuthenticated(user);
    } else {
      onUserNotAuthenticated();
    }
  });
};

export const saveVenue = async (venueData) => {
  const currentDate = new Date();
  const dateString = currentDate.toISOString().replace(/[-:T.]/g, '').slice(0, 14); // Format the date as YYYYMMDDHHmmss
  const documentID = `${venueData.venueid}_${dateString}`;

  await setDoc(doc(db, 'venueItems', documentID), venueData);
};

// Function to fetch venue items from Firestore based on the user's UID
export const fetchVenueItems = async (uid) => {
  const venueQuery = uid 
    ? query(collection(db, 'venueItems'), where('venueOwnerID', '==', uid))
    : collection(db, 'venueItems');
  const data = await getDocs(venueQuery);
  const itemsWithImages = await Promise.all(data.docs.map(async (doc) => {
    const venueData = doc.data();
    const imageRef = ref(getStorage(), venueData.imageURL);
    const imageDownloadUrl = await getDownloadURL(imageRef).catch(() => "");
    return { ...venueData, id: doc.id, imageDownloadUrl };
  }));
  return itemsWithImages;
};

// export const fetchVenueItems = async () => {
//   const venueItemsCol = collection(db, 'venueItems');
//   const data = await getDocs(venueItemsCol);
//   const itemsWithImages = await Promise.all(data.docs.map(async (doc) => {
//     const venueData = doc.data();
//     const imageRef = ref(getStorage(), venueData.imageURL);
//     const imageDownloadUrl = await getDownloadURL(imageRef).catch(() => "");
//     return { ...venueData, id: doc.id, imageDownloadUrl };
//   }));
//   return itemsWithImages;
// };

export const saveParsedMenuItems = async (parsedData) => {
  console.log("saveParsedMenuItems function called with data:", parsedData);

  if (!db) {
    console.error("Firestore database instance is not initialized");
    throw new Error("Database not initialized");
  }

  if (!Array.isArray(parsedData) || parsedData.length === 0) {
    throw new Error("Invalid or empty data provided");
  }

  const savedItems = [];
  const errors = [];

  for (const item of parsedData) {
    try {
      if (!item['Menu Item Name'] || !item['Price (£)'] || !item['menuType'] || !item['venueid']) {
        console.warn(`Skipping invalid item:`, item);
        continue;
      }

      const price = parseFloat(item['Price (£)'].replace('£', '').trim());

      if (isNaN(price)) {
        console.warn(`Invalid price for item:`, item);
        continue;
      }

      const docRef = await addDoc(collection(db, 'parsedMenuItems'), {
        name: item['Menu Item Name'].trim(),
        price: price,
        menuType: item['menuType'].trim(),
        venueid: item['venueid'].trim(),
        createdAt: serverTimestamp()
      });

      savedItems.push(docRef.id);
      console.log("Document written with ID: ", docRef.id);
    } catch (error) {
      console.error("Error adding document: ", error);
      errors.push({ item, error: error.message });
    }
  }

  console.log(`Saved ${savedItems.length} items. Errors encountered: ${errors.length}`);

  if (errors.length > 0) {
    console.error("Errors:", errors);
  }

  return { savedItems, errors };
};

export const downloadMenuItems = async () => {
  console.log("downloadMenuItems function called");

  const snapshot = await getDocs(collection(db, 'menuItems'));
  const menuItems = snapshot.docs.map(doc => doc.data());

  const csvData = menuItems.map(item => ({
    venueid: item.venueid,
    menuType: item.menuType,
    extractedText: item.extractedText,
    timestamp: new Date().toISOString()
  }));

  const csv = Papa.unparse(csvData);

  return csv;
};



export const fetchMenuItems = async () => {
  const response = collection(db, 'menuItems');
  const data = await getDocs(response);
  const menuItemsWithImages = await Promise.all(data.docs.map(async (doc) => {
    const menuItemData = doc.data();
    const imageRef = ref(getStorage(), menuItemData.imageURL);
    const imageDownloadUrl = await getDownloadURL(imageRef).catch(() => "default-image.jpg"); // Provide a default image if no URL is found
    return { ...menuItemData, id: doc.id, imageDownloadUrl };
  }));
  console.log('Fetched Menu Items:', menuItemsWithImages); // Log the fetched menu items
  return menuItemsWithImages;
};

// Function to fetch users from Firestore
export const fetchUsers = async () => {
  const usersCollectionRef = collection(db, 'users');
  const data = await getDocs(usersCollectionRef);
  const users = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  return users;
};

// Function to update user data in Firebase
export const updateUser = async (userId, updatedUserData) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, updatedUserData);
    console.log("User updated successfully");
  } catch (error) {
    throw new Error("Error updating user: " + error.message);
  }
};

export const registerUser = async (email, password, firstName, lastName) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    if (user) {
      // Now we have the user registered, let's add their first and last name to Firestore
      await setDoc(doc(db, 'users', user.uid), {
        firstName: firstName,
        lastName: lastName
      });
      return { status: 'success', message: 'User registered successfully' };
    }
  } catch (error) {
    // Handle errors here, such as email already in use or weak password
    return { status: 'error', message: error.message };
  }
};

// Function to update venue details
export const updateVenuePromo = async (venueId, updateData) => {
  try {
    const venueRef = doc(db, 'venueItems', venueId);
    await updateDoc(venueRef, updateData);
    console.log("Venue details updated successfully");
  } catch (error) {
    throw new Error("Error updating venue details: " + error.message);
  }
};

// Function to fetch menu items by venueId
export const fetchMenuItemsByVenue = async (venueId) => {
  const menuQuery = query(collection(db, 'menuItems'), where('venueid', '==', venueId));
  const data = await getDocs(menuQuery);
  const itemsWithImages = await Promise.all(data.docs.map(async (doc) => {
    const menuItemData = doc.data();
    const imageRef = ref(getStorage(), menuItemData.imageURL);
    const imageDownloadUrl = await getDownloadURL(imageRef).catch(() => "");
    return { ...menuItemData, id: doc.id, imageDownloadUrl };
  }));
  return itemsWithImages;
};

// Function to update menu summary
export const updateMenuSummary = async (menuId, newSummary) => {
  const menuRef = doc(db, 'menuItems', menuId);
  await updateDoc(menuRef, { output: newSummary });
  console.log("Menu summary updated successfully");
};

// Function to delete menu item
export const deleteMenuItem = async (menuId, imageURL) => {
  const menuRef = doc(db, 'menuItems', menuId);
  const storage = getStorage();
  
  // Convert gs:// URL to path
  const path = imageURL.replace(`gs://${storage.app.options.storageBucket}/`, '');
  const imageRef = ref(storage, path);

  try {
    await deleteDoc(menuRef);
    await deleteObject(imageRef);
    console.log("Menu item and image deleted successfully");
  } catch (error) {
    throw new Error("Error deleting menu item: " + error.message);
  }
};

export const uploadImageAndSaveMenu = async ({ image, venueId, venueName, description, menuType }) => {
  const storage = getStorage();
  const dateString = new Date().toISOString().replace(/[^0-9]/g, "");
  const imageName = `${venueId}_${dateString}.jpg`;
  const storagePath = `menuItems/${imageName}`;
  const storageRef = ref(storage, storagePath);

  const uploadResult = await uploadBytes(storageRef, image);
  const downloadUrl = await getDownloadURL(uploadResult.ref);

  const newItem = {
    id: imageName,
    title: venueName,
    description,
    venueid: venueId,
    imageURL: downloadUrl,
    active: true,
    menuType
  };

  const menuRef = doc(db, 'menuItems', imageName);
  await setDoc(menuRef, newItem);

  console.log('Menu item and image uploaded successfully');
};

// Function to update the menu output field in Firestore
export const updateMenuOutput = async (menuId, output) => {
  const menuRef = doc(db, 'menuItems', menuId);
  await updateDoc(menuRef, { output });
  console.log("Menu output updated successfully");
};

// Function to trigger AI generation (placeholder for actual implementation)
export const generateMenuOutput = async (menuId) => {
  try {
    // Placeholder: Replace with actual AI generation logic
    const generatedOutput = `Generated output from AI for menuId: ${menuId}`;

    // Update the Firestore document with the generated output
    await updateMenuOutput(menuId, generatedOutput);
    console.log("Menu output updated successfully");
  } catch (error) {
    console.error("Error generating menu output:", error);
  }
};

// Function to update menu type
export const updateMenuType = async (menuId, newType) => {
  const menuRef = doc(db, 'menuItems', menuId);
  await updateDoc(menuRef, { menuType: newType });
  console.log("Menu type updated successfully");
};

// Function to get current user's email
export const getCurrentUserEmail = () => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, user => {
      if (user) {
        resolve(user.email);
      } else {
        reject('No user logged in');
      }
    });
  });
};