import React, { useState, useContext } from 'react';
import { VenueContext } from '../contexts/VenueContext';
import CSVUpload from '../components/CSVUpload'; // Import the CSVUpload component
import { downloadMenuItems } from '../services/firebaseService'; // Import the download function
import './AddVenue.css';

const AddVenue = () => {
  const { users, addVenue } = useContext(VenueContext);
  const [venueName, setVenueName] = useState('');
  const [venueid, setVenueid] = useState('');
  const [address, setAddress] = useState('');
  const [description, setDescription] = useState('');
  const [cuisine, setCuisine] = useState('');
  const [payment, setPayment] = useState('');
  const [telephone, setTelephone] = useState('');
  const [website, setWebsite] = useState('');
  const [imageURL, setImageURL] = useState('gs://spenu2311.appspot.com/venueitems/default.jpg');
  const [active, setActive] = useState(false);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [promo, setPromo] = useState('');
  const [price, setPrice] = useState('');
  const [venueOwnerID, setVenueOwnerID] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSave = async (e) => {
    e.preventDefault();
    const lat = parseFloat(latitude) || 0.0;
    const long = parseFloat(longitude) || 0.0;
    const venueData = {
      venuename: venueName,
      venueid,
      address,
      description,
      cuisine,
      payment,
      telephone,
      website,
      imageURL,
      active,
      latitude: lat,
      longitude: long,
      promo,
      price,
      venueOwnerID,
      email,
    };
    await addVenue(venueData);
  };

  const handleDownloadMenuItems = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const csvContent = await downloadMenuItems();
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `menuItems_${new Date().toISOString()}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading menu items:", error);
      setError(`Failed to download menu items: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="add-venue">
      <CSVUpload /> {/* Add CSVUpload component here */}
      <form onSubmit={handleSave}>
        <h2>Add Venue</h2>
        <input
          type="text"
          placeholder="Venue Name"
          value={venueName}
          onChange={(e) => setVenueName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Venue ID"
          value={venueid}
          onChange={(e) => setVenueid(e.target.value)}
        />
        <select value={venueOwnerID} onChange={(e) => setVenueOwnerID(e.target.value)}>
          {users.map(user => (
            <option key={user.id} value={user.id}>{user.email}</option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <input
          type="text"
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <input
          type="text"
          placeholder="Cuisine"
          value={cuisine}
          onChange={(e) => setCuisine(e.target.value)}
        />
        <input
          type="text"
          placeholder="Payment"
          value={payment}
          onChange={(e) => setPayment(e.target.value)}
        />
        <input
          type="text"
          placeholder="Telephone"
          value={telephone}
          onChange={(e) => setTelephone(e.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="text"
          placeholder="Website"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
        />
        <input
          type="text"
          placeholder="Image URL"
          value={imageURL}
          onChange={(e) => setImageURL(e.target.value)}
        />
        <label>
          Active:
          <input
            type="checkbox"
            checked={active}
            onChange={(e) => setActive(e.target.checked)}
          />
        </label>
        <input
          type="text"
          placeholder="Latitude"
          value={latitude}
          onChange={(e) => setLatitude(e.target.value)}
        />
        <input
          type="text"
          placeholder="Longitude"
          value={longitude}
          onChange={(e) => setLongitude(e.target.value)}
        />
        <input
          type="text"
          placeholder="Promo"
          value={promo}
          onChange={(e) => setPromo(e.target.value)}
        />
        <input
          type="text"
          placeholder="Price"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <button type="submit">Save</button>
      </form>
      <button onClick={handleDownloadMenuItems} disabled={isLoading}>
        {isLoading ? 'Downloading...' : 'Download Menu Items'}
      </button>
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </div>
  );
};

export default AddVenue;